<template>
  <footer id="footer" v-if="!mp">
    <div class="layout">
      <div class="links">
        <dl class="mod">
          <dt class="checked" @click="checkMod">
            <router-link to="/vehicles">橙仕汽车</router-link>
          </dt>
          <dd>
            <router-link to="/cs01">橙仕01</router-link>
            <router-link to="/csx2">橙仕X2</router-link>
            <router-link to="/csx7">橙仕X7</router-link>
            <router-link to="/chassis">橙仕X2/X7二类底盘</router-link>
            <!-- <router-link to="/cs00">橙仕00</router-link> -->
          </dd>
        </dl>
        <dl class="mod">
          <dt class="checked" @click="checkMod">
            <router-link to="/antiv">场景化造车</router-link>
          </dt>
          <dd>
            <router-link to="/samplingX2">X2智能健康服务车</router-link>
            <router-link to="/sampling01">01智能健康服务车</router-link>
            <router-link to="/svaas">防疫车管理系统</router-link>
            <router-link to="/ciie">2022进博会</router-link>
            <router-link to="/cafevan">魔方咖啡车</router-link>
            <router-link to="/ec">社区团购车</router-link>
          </dd>
        </dl>
        <dl class="mod">
          <dt class="checked" @click="checkMod">
            <router-link to="/technical">矩阵实验室</router-link>
          </dt>
          <dd>
            <router-link to="/op">橙仕01D开放平台</router-link>
            <router-link to="/0zai">0ZAI零载无人车</router-link>
            <!-- <a href="http://0zai.com/" target="_blank">0ZAI开拓者号</a> -->
            <router-link to="/zz">橙仕ZZ</router-link>
            <router-link to="/water">水上机器人</router-link>
            <router-link to="/ai">橙仕大脑</router-link>
          </dd>
        </dl>
        <dl class="mod">
          <dt class="checked" @click="checkMod">
            <router-link to="/vbd">智能车联网</router-link>
          </dt>
          <dd>
            <router-link to="/vcu">VCU</router-link>
            <router-link to="/tbox">T-Box</router-link>
            <router-link to="/ydkey">汽车智能数字钥匙</router-link>
            <router-link to="/edr">EDR</router-link>
          </dd>
        </dl>
        <dl class="mod">
          <dt class="checked" @click="checkMod">
            <router-link to="/about">关于我们</router-link>
          </dt>
          <dd>
            <!-- <router-link to="/">公司简介</router-link> -->
            <router-link to="/industrial">矩阵工业系统</router-link>
            <!-- <router-link to="/factory">矩阵工厂</router-link> -->
            <router-link to="/partner">橙仕合伙人</router-link>
            <router-link to="/join">加入我们</router-link>
            <router-link to="/news">媒体中心</router-link>
            <router-link to="/service">售后服务</router-link>
          </dd>
        </dl>
        <dl class="contact">
          <dt>全国统一电话</dt>
          <dd>
            <a href="tel:4007991212">400 799 1212</a>
          </dd>
          <dt>人才招聘</dt>
          <dd>
            <a href="mailto:hr@juzhen.cn">hr@juzhen.cn</a>
          </dd>
        </dl>
        <!-- <div class="wechat">
          <p id="J_wechatTxt">长按二维码关注官方微信</p>
          <img
            class="logo"
            v-lazy="`https://alihtml.juzhen.cn/juzhen/assets/img/qrcode_jz.jpg`"
            width="110"
            height="110"
          />
        </div> -->
      </div>

      <div class="platform">
        <router-link class="logo" to="/">
          <svg v-if="siteType === 'allcheer'" xmlns="http://www.w3.org/2000/svg" width="180" height="46" viewBox="0 0 180 46">
            <path fill="currentColor" d="M71.28 13.86h-3.09a8.332 8.332 0 0 0-8.333 8.332v14.783a8.34 8.34 0 0 0 8.332 8.332h3.091a8.34 8.34 0 0 0 8.332-8.332V32.18h-7.525v6.139a1.613 1.613 0 0 1-1.613 1.612h-.403a2.15 2.15 0 0 1-2.15-2.15V21.386a2.15 2.15 0 0 1 2.15-2.15h.403a1.613 1.613 0 0 1 1.613 1.613v7.3h7.525v-5.957a8.332 8.332 0 0 0-8.332-8.332zm48.783 0h-3.09a8.332 8.332 0 0 0-8.333 8.332v14.783a8.34 8.34 0 0 0 8.332 8.332h3.091a8.34 8.34 0 0 0 8.332-8.332V32.18l-7.526.537v5.602a1.613 1.613 0 0 1-1.612 1.612h-.403a2.15 2.15 0 0 1-2.15-2.15v-7.929h11.691v-7.66a8.332 8.332 0 0 0-8.332-8.332zm-3.36 11.692v-4.166a2.15 2.15 0 0 1 2.15-2.15h.403a1.613 1.613 0 0 1 1.613 1.613v4.703zm27.55-11.692h-3.091a8.332 8.332 0 0 0-8.332 8.332v14.783a8.34 8.34 0 0 0 8.332 8.332h3.09a8.34 8.34 0 0 0 8.333-8.332V32.18l-7.526.537v5.602a1.613 1.613 0 0 1-1.612 1.612h-.404a2.15 2.15 0 0 1-2.15-2.15v-7.929h11.692v-7.66a8.332 8.332 0 0 0-8.332-8.332zm-3.36 11.692v-4.166a2.15 2.15 0 0 1 2.15-2.15h.403a1.613 1.613 0 0 1 1.613 1.613v4.703zM180 45.173l-7.03-13.347a6.988 6.988 0 0 0 6.224-6.946v-2.419a8.6 8.6 0 0 0-8.601-8.6h-13.439v31.312h7.66v-15.82l8.332 15.82zm-15.186-17.605v-8.87h3.629a3.225 3.225 0 0 1 3.225 3.225v3.226a2.42 2.42 0 0 1-2.419 2.419h-4.435zM0 45.173h9.829l2.976-12.321h7.094v12.32h8.753V2.747Q19.997 1.839 11.608.693zM19.9 9.168v18.309h-5.797l4.422-18.31zm14.424 36.005h8.063V4.019q-4.057-.328-8.063-.707zm12.901 0h8.063V4.92q-4.054-.24-8.063-.53zM99.099 13.86h-.807a9.109 9.109 0 0 0-6.45 2.666V5.998q-4.048-.001-8.064-.054v39.094h8.064V21.386a2.15 2.15 0 0 1 2.15-2.15h.537a1.613 1.613 0 0 1 1.613 1.613v24.19h8.063V18.967A5.107 5.107 0 0 0 99.1 13.86z"/>
          </svg>
          <svg v-else-if="siteType === 'cssmart'" xmlns="http://www.w3.org/2000/svg" width="100" height="50" viewBox="0 0 100 50">
            <g fill="currentColor">
              <path d="M21.33 23.958l1.708-6.13h-4.661l.842-7.82h2.255l1.708-6.13h-3.303L20.296 0h-6.131l-.463 3.878H7.657l-1.192 6.13h6.504l-.094.789h-5.01L1 43.818h6.158l4.168-20.049L8.193 50l7.019-2.756 2.506-23.286h3.612z"/>
              <path d="M47.186 35.185h-6.21l-.905 2.502H32.99l-.379-2.502h-6.145l.379 2.502h-9.461l-.645 6.131h37.613l.645-6.131h-8.715l.904-2.502z"/>
              <path d="M69.001 1.123h-6.254l-9.53 20.995h5.933l-3.573 27.707 6.973-1.442 3.765-34.307h-3.193l5.879-12.953zM98.607 20.207l.645-6.131H86.79L88.177.882l-6.893 2.036-1.018 11.158H68.191l-.644 6.131h12.159l-1.595 17.48h-9.814l-.645 6.131h27.164l.645-6.131H84.309l1.837-17.48h12.461zM35.222 13.348l-2.435 6.131h10.208l.906-1.834.428 1.834h6.414l-.849-3.636h3.307l1.708-6.131h-6.447l-.236-1.01h8.263l1.708-6.131H46.794l-.338-1.448h-6.414l2.855 12.225h-7.675zM29.612 7.253l-.497 1.136h-5.874l-1.708 6.131h4.896l-2.156 4.922h7.154L37.52 6.029l-1.594-4.906H24.85l-1.708 6.13h6.47zM49.077 34.37l2.396-13.954H24.599L22.203 34.37h18.55l1.703-6.131H29.878l.178-1.692h14.503l-1.727 7.823h6.245z"/>
            </g>
          </svg>
          <svg v-else xmlns="http://www.w3.org/2000/svg" width="176" height="97" viewBox="0 0 176 97"><path fill-rule="evenodd" fill="currentColor" d="M175.964 70.429v13.337h-10.857l.13 13.039-13.608.131-.131-13.17h-27.154l2.346-13.337h24.676l-.121-12.185h-13.581c-.454 0-.912-.041-1.36-.12a7.694 7.694 0 0 1-4.986-3.119 7.418 7.418 0 0 1-1.278-5.669l3.498-19.373h-7.786l2.464-13.336h7.73L138.775.969l13.401 2.325-2.408 13.333h26.196v13.336h-28.605l-2.699 14.945h6.453l-.086-8.672 13.608-.13.088 8.802h11.241v13.336h-11.11l.122 12.185h10.988zm-53.959-37.176h6.643l-8.593 50.423.065.09h-11.732l6.961-39.487c-3.071-.18-5.514-2.684-5.514-5.737v-.425l.068-.452 3.641-22.877h-2.809V3.751h8.29c.34 0 .686.028 1.022.079a6.537 6.537 0 0 1 4.283 2.563 6.298 6.298 0 0 1 1.186 4.79l-3.511 22.07zM94.076 3.061h13.609V96.87H94.076V3.061zm-21.352 66.68H54.186v13.794h26.75V96.87H49.259c-4.786 0-8.681-3.815-8.681-8.504V11.569c0-4.689 3.895-8.508 8.681-8.508h31.677v13.336h-26.75v40.007h13.609V43.069h-8.838V29.733h13.767c4.786 0 8.682 3.816 8.682 8.508v22.994c0 4.689-3.896 8.506-8.682 8.506zM20.918 88.386l8.815-10.162 9.386 7.82-8.815 10.16-9.386-7.818zm8.397-16.179a7.49 7.49 0 0 1-2.031 5.123L10.636 95.163l-10.041-9 15.11-16.188V50.427H1.863V37.09h13.842V23.984h13.61V37.09h6.335v13.337h-6.335v21.78zM13.081 30.933L.029 27.156l4.649-15.435c.966-3.203 4.02-5.441 7.427-5.441H35.65v13.337H16.49l-3.409 11.316z"></path></svg>
        </router-link>
        <div class="qrcodes">
          <img class="img" src="../assets/images/qrcode/douyin.png" width="100" height="100" alt="橙仕抖音账号二维码" />
          <img class="img" src="../assets/images/qrcode/weixin.png" width="100" height="100" alt="橙仕微信账号二维码" />
        </div>
      </div>

      <!-- <router-link class="logo" to="/">
        <svg xmlns="http://www.w3.org/2000/svg" width="176" height="97" viewBox="0 0 176 97">
          <path fill-rule="evenodd" fill="currentColor" d="M175.964 70.429v13.337h-10.857l.13 13.039-13.608.131-.131-13.17h-27.154l2.346-13.337h24.676l-.121-12.185h-13.581c-.454 0-.912-.041-1.36-.12a7.694 7.694 0 0 1-4.986-3.119 7.418 7.418 0 0 1-1.278-5.669l3.498-19.373h-7.786l2.464-13.336h7.73L138.775.969l13.401 2.325-2.408 13.333h26.196v13.336h-28.605l-2.699 14.945h6.453l-.086-8.672 13.608-.13.088 8.802h11.241v13.336h-11.11l.122 12.185h10.988zm-53.959-37.176h6.643l-8.593 50.423.065.09h-11.732l6.961-39.487c-3.071-.18-5.514-2.684-5.514-5.737v-.425l.068-.452 3.641-22.877h-2.809V3.751h8.29c.34 0 .686.028 1.022.079a6.537 6.537 0 0 1 4.283 2.563 6.298 6.298 0 0 1 1.186 4.79l-3.511 22.07zM94.076 3.061h13.609V96.87H94.076V3.061zm-21.352 66.68H54.186v13.794h26.75V96.87H49.259c-4.786 0-8.681-3.815-8.681-8.504V11.569c0-4.689 3.895-8.508 8.681-8.508h31.677v13.336h-26.75v40.007h13.609V43.069h-8.838V29.733h13.767c4.786 0 8.682 3.816 8.682 8.508v22.994c0 4.689-3.896 8.506-8.682 8.506zM20.918 88.386l8.815-10.162 9.386 7.82-8.815 10.16-9.386-7.818zm8.397-16.179a7.49 7.49 0 0 1-2.031 5.123L10.636 95.163l-10.041-9 15.11-16.188V50.427H1.863V37.09h13.842V23.984h13.61V37.09h6.335v13.337h-6.335v21.78zM13.081 30.933L.029 27.156l4.649-15.435c.966-3.203 4.02-5.441 7.427-5.441H35.65v13.337H16.49l-3.409 11.316z"/>
        </svg>
      </router-link> -->
      <!-- <ul class="sns">
        <li>
          <svg xmlns="http://www.w3.org/2000/svg" width="31.681" height="25.81" viewBox="0 0 31.681 25.81">
            <path
              d="M21.444,102.719a9.879,9.879,0,0,1,1.083.067c-.97-4.519-5.8-7.875-11.314-7.875C5.049,94.912,0,99.112,0,104.448c0,3.079,1.679,5.608,4.487,7.572l-1.121,3.372,3.921-1.964a19.316,19.316,0,0,0,3.927.56c.352,0,.7-.016,1.045-.042a8.406,8.406,0,0,1-.347-2.352C11.914,106.7,16.12,102.719,21.444,102.719ZM15.417,99.68a1.4,1.4,0,1,1,0,2.8,1.424,1.424,0,1,1,0-2.8Zm-7.847,2.8a1.425,1.425,0,1,1,0-2.8,1.4,1.4,0,0,1,0,2.8Zm24.112,8.978c0-4.483-4.487-8.136-9.526-8.136-5.336,0-9.536,3.655-9.536,8.136s4.2,8.136,9.536,8.136a14.063,14.063,0,0,0,3.364-.56l3.075,1.687-.844-2.8C30,116.227,31.681,113.988,31.681,111.459Zm-12.619-1.4a1.121,1.121,0,1,1,0-2.241,1.149,1.149,0,1,1,0,2.241Zm6.168,0a1.121,1.121,0,0,1,0-2.241,1.149,1.149,0,1,1,0,2.241Z"
              transform="translate(0 -94.912)" fill="currentColor" />
          </svg>
          <img src="https://juzhenimage.oss-cn-shanghai.aliyuncs.com/img/wechat.png" width="240" height="271" />
        </li>
        <li><a href="https://weibo.com/u/7464275048" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="32.941" height="25.968" viewBox="0 0 32.941 25.968">
              <path
                d="M88.331,140.054c-1.28-.247-.657-.924-.657-.924s1.253-2.04-.251-3.524c-1.859-1.836-6.385.231-6.385.231-1.726.528-1.269-.242-1.024-1.551,0-1.545-.535-4.156-5.138-2.617-4.6,1.556-8.545,6.993-8.545,6.993-2.744,3.612-2.382,6.405-2.382,6.405.685,6.169,7.326,7.862,12.492,8.264,5.433.418,12.77-1.848,14.991-6.51s-1.815-6.515-3.1-6.768Zm-11.49,11.541c-5.394.247-9.759-2.419-9.759-5.971s4.364-6.405,9.759-6.653,9.77,1.952,9.77,5.5S82.241,151.352,76.842,151.594Zm-1.074-10.287a5.029,5.029,0,0,0-4.8,5.641,2.759,2.759,0,0,0,1.453,2.4,6.341,6.341,0,0,0,8.094-1.435C82.169,145.81,81.2,140.686,75.767,141.307ZM74.4,148.35a1.482,1.482,0,0,1-1.831-1.3,1.927,1.927,0,0,1,1.737-1.809c1.164-.11,1.921.55,1.921,1.391A1.943,1.943,0,0,1,74.4,148.35Zm3.2-2.689a.63.63,0,0,1-.946-.088.779.779,0,0,1,.228-1.017.661.661,0,0,1,1,.088A.831.831,0,0,1,77.6,145.662Zm13.377-7.78a.883.883,0,0,0,.874-.737.53.53,0,0,0,.011-.094c.663-5.877-4.882-4.866-4.882-4.866a.88.88,0,1,0,0,1.759c3.98-.869,3.106,3.062,3.106,3.062A.876.876,0,0,0,90.976,137.882Zm-.646-10.259a11.184,11.184,0,0,0-4.442.044c-.045.006-.083.044-.122.049a.105.105,0,0,0-.033.022,1.279,1.279,0,0,0,.351,2.513,6.364,6.364,0,0,0,1.169-.275,6.857,6.857,0,0,1,6.413,3.128,5.623,5.623,0,0,1,.4,4.239,3.738,3.738,0,0,0-.256,1.232,1.181,1.181,0,0,0,1.291,1.149c.6,0,1.1-.083,1.241-1.078h.006C98.469,131.68,93.759,128.409,90.33,127.622Zm0,0"
                transform="translate(-63.933 -127.413)" fill="currentColor" />
            </svg>
          </a></li>
      </ul> -->
      <p v-if="siteType === 'cssmart'" class="copyright">
        <span>Copyright &copy; 2021 山东豪驰智能汽车有限公司 版权所有</span>
        <span><a href="http://beian.miit.gov.cn/" target="_blank" rel="nofollow">鲁ICP备18034186号-2</a>
          <a href="https://alihtml.juzhen.cn/juzhen/assets/img/661600222011_.pic_hd.png" target="_blank">证照中心</a>
        </span>
      </p>
      <p v-else class="copyright">
        <span>&copy;2022 矩阵（上海）汽车科技有限公司 版权所有</span>
        <span>
          <a href="http://beian.miit.gov.cn/" target="_blank" rel="nofollow">沪ICP备20020472号</a>
          <a href="https://alihtml.juzhen.cn/juzhen/assets/img/661600222011_.pic_hd.png" target="_blank">证照中心</a>
        </span>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    const { mp } = this.$route.meta;
    const { pathname } = window.location;

    return {
      mp: mp || pathname === '/StvehicleUr',
    }
  },
  watch: {
    $route (to) {
      const { mp } = to.meta;

      this.mp = mp;
    }
  },
  methods: {
    checkMod() {
      // const { target } = el;
      
      // target.classList.toggle('checked');
    },
  }
};
</script>

<style lang="scss">
#footer {
  padding: 86px 0 20px;
  font-size: 12px;
  line-height: 24px;
  color: #999;
  font-family: 'PingFang SC';
  background-color: #222226;

  a {
    color: #888;
  }

  a:hover {
    color: #ff3000;
  }

  .layout {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1240px;
  }

  .logo {
    padding: 4px 0;
    color: #999;

    svg {
      display: block;
      width: 88px;
      height: auto;
    }
  }

  .qrcodes {
    margin-top: 20px;
    display: flex;

    .img {
      margin-right: 24px;
    }
  }

  .links {
    margin: 0 40px 0 80px;
    display: flex;
    // flex: 1;

    .mod {
      & + .mod {
        margin-left: 40px;
      }

      dt {
        margin-bottom: 8px;
        font-size: 14px;

        a {
          color: #999;
        }
      }

      a {
        display: block;
      }
    }

    .contact {
      flex: 1;
      margin-left: 60px;

      dt {
        margin-bottom: 4px;
      }

      dd + dt {
        margin-top: 20px;
      }

      a {
        display: block;
        font-size: 16px;
      }
    }

    .wechat {
      p {
        margin-bottom: 12px;
      }

      .logo {
        opacity: .8;
        border-radius: 8px;
      }
    }
  }

  .copyright {
    width: 100%;
    margin-top: 100px;
    font-size: 12px;
    line-height: 1.5;
    color: #666;
    text-align: center;

    a {
      color: #666;

      & + a {
        margin-left: 6px;
      }
    }

    span {
      white-space: nowrap;

      & + span {
        margin-left: 15px;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  #footer {
    padding: 24px 16px 8px;
    font-size: 12px;

    .layout {
      display: block;
      max-width: 100%;
    }

    .platform {
      margin: 24px auto 32px;
    }

    .logo {
      display: inline-block;
      vertical-align: top;
      width: 100%;

      svg {
        margin: 0 auto;
        width: 58px;
      }
    }

    .qrcodes {
      justify-content: center;

      .img {
        margin: 0 12px;
      }
    }

    .links {
      margin: 0;
      flex-wrap: wrap;

      .mod, .contact {
        margin-left: 0;
        width: 100%;
        border-top: 1px solid #999;

        & + .mod {
          margin-left: 0;
        }

        dt {
          margin-bottom: 0;
          padding: 8px;
          font-size: 12px;
          line-height: 20px;
        }

        dd {
          margin-bottom: 12px;
          padding: 0 20px;
        }
      }

      .mod {
        position: relative;
        overflow: hidden;

        dt {
          position: relative;
          z-index: 2;
          cursor: pointer;
          background-color: #222226;

          &::after {
            float: right;
            margin-top: -2px;
            content: '+';
            font-size: 14px;
            font-family: inherit;
            transition: transform 300ms ease;
          }

          &.checked::after {
            transform: rotate(45deg) scale(1.08);
          }

          &.checked + dd {
            position: static;
            visibility: visible;
            z-index: 1;
            transform: none;
          }
        }

        dd {
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          visibility: hidden;
          z-index: -1;
          transform: translateY(-100px);
          transition: transform 300ms ease;
        }
      }

      .contact {
        dd + dt {
          margin-top: 0;
        }

        a {
          font-size: 14px;
        }
      }

      .wechat {
        width: 150px;
        border-top: 1px solid #999;

        p {
          margin-bottom: 6px;
          line-height: 36px;
        }

        img {
          width: 80px;
          height: auto;
        }
      }
    }

    .copyright {
      margin-top: 0;
      padding-top: 8px;
      line-height: 1.2;
      border-top: 1px solid #999;


      span {
        display: block;

        & + span {
          margin: 0;
        }
      }
    }
  }
}
</style>
