<template>
  <header id="header" v-if="!mp" :class="style">
    <div class="layout">
      <router-link class="logo" to="/">
        <svg v-if="siteType === 'allcheer'" xmlns="http://www.w3.org/2000/svg" width="180" height="46" viewBox="0 0 180 46">
          <path fill="currentColor" d="M71.28 13.86h-3.09a8.332 8.332 0 0 0-8.333 8.332v14.783a8.34 8.34 0 0 0 8.332 8.332h3.091a8.34 8.34 0 0 0 8.332-8.332V32.18h-7.525v6.139a1.613 1.613 0 0 1-1.613 1.612h-.403a2.15 2.15 0 0 1-2.15-2.15V21.386a2.15 2.15 0 0 1 2.15-2.15h.403a1.613 1.613 0 0 1 1.613 1.613v7.3h7.525v-5.957a8.332 8.332 0 0 0-8.332-8.332zm48.783 0h-3.09a8.332 8.332 0 0 0-8.333 8.332v14.783a8.34 8.34 0 0 0 8.332 8.332h3.091a8.34 8.34 0 0 0 8.332-8.332V32.18l-7.526.537v5.602a1.613 1.613 0 0 1-1.612 1.612h-.403a2.15 2.15 0 0 1-2.15-2.15v-7.929h11.691v-7.66a8.332 8.332 0 0 0-8.332-8.332zm-3.36 11.692v-4.166a2.15 2.15 0 0 1 2.15-2.15h.403a1.613 1.613 0 0 1 1.613 1.613v4.703zm27.55-11.692h-3.091a8.332 8.332 0 0 0-8.332 8.332v14.783a8.34 8.34 0 0 0 8.332 8.332h3.09a8.34 8.34 0 0 0 8.333-8.332V32.18l-7.526.537v5.602a1.613 1.613 0 0 1-1.612 1.612h-.404a2.15 2.15 0 0 1-2.15-2.15v-7.929h11.692v-7.66a8.332 8.332 0 0 0-8.332-8.332zm-3.36 11.692v-4.166a2.15 2.15 0 0 1 2.15-2.15h.403a1.613 1.613 0 0 1 1.613 1.613v4.703zM180 45.173l-7.03-13.347a6.988 6.988 0 0 0 6.224-6.946v-2.419a8.6 8.6 0 0 0-8.601-8.6h-13.439v31.312h7.66v-15.82l8.332 15.82zm-15.186-17.605v-8.87h3.629a3.225 3.225 0 0 1 3.225 3.225v3.226a2.42 2.42 0 0 1-2.419 2.419h-4.435zM0 45.173h9.829l2.976-12.321h7.094v12.32h8.753V2.747Q19.997 1.839 11.608.693zM19.9 9.168v18.309h-5.797l4.422-18.31zm14.424 36.005h8.063V4.019q-4.057-.328-8.063-.707zm12.901 0h8.063V4.92q-4.054-.24-8.063-.53zM99.099 13.86h-.807a9.109 9.109 0 0 0-6.45 2.666V5.998q-4.048-.001-8.064-.054v39.094h8.064V21.386a2.15 2.15 0 0 1 2.15-2.15h.537a1.613 1.613 0 0 1 1.613 1.613v24.19h8.063V18.967A5.107 5.107 0 0 0 99.1 13.86z"/>
        </svg>
        <svg v-else-if="siteType === 'cssmart'" xmlns="http://www.w3.org/2000/svg" width="100" height="50" viewBox="0 0 100 50">
          <g fill="currentColor">
            <path d="M21.33 23.958l1.708-6.13h-4.661l.842-7.82h2.255l1.708-6.13h-3.303L20.296 0h-6.131l-.463 3.878H7.657l-1.192 6.13h6.504l-.094.789h-5.01L1 43.818h6.158l4.168-20.049L8.193 50l7.019-2.756 2.506-23.286h3.612z"/>
            <path d="M47.186 35.185h-6.21l-.905 2.502H32.99l-.379-2.502h-6.145l.379 2.502h-9.461l-.645 6.131h37.613l.645-6.131h-8.715l.904-2.502z"/>
            <path d="M69.001 1.123h-6.254l-9.53 20.995h5.933l-3.573 27.707 6.973-1.442 3.765-34.307h-3.193l5.879-12.953zM98.607 20.207l.645-6.131H86.79L88.177.882l-6.893 2.036-1.018 11.158H68.191l-.644 6.131h12.159l-1.595 17.48h-9.814l-.645 6.131h27.164l.645-6.131H84.309l1.837-17.48h12.461zM35.222 13.348l-2.435 6.131h10.208l.906-1.834.428 1.834h6.414l-.849-3.636h3.307l1.708-6.131h-6.447l-.236-1.01h8.263l1.708-6.131H46.794l-.338-1.448h-6.414l2.855 12.225h-7.675zM29.612 7.253l-.497 1.136h-5.874l-1.708 6.131h4.896l-2.156 4.922h7.154L37.52 6.029l-1.594-4.906H24.85l-1.708 6.13h6.47zM49.077 34.37l2.396-13.954H24.599L22.203 34.37h18.55l1.703-6.131H29.878l.178-1.692h14.503l-1.727 7.823h6.245z"/>
          </g>
        </svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg" width="176" height="97" viewBox="0 0 176 97"><path fill-rule="evenodd" fill="currentColor" d="M175.964 70.429v13.337h-10.857l.13 13.039-13.608.131-.131-13.17h-27.154l2.346-13.337h24.676l-.121-12.185h-13.581c-.454 0-.912-.041-1.36-.12a7.694 7.694 0 0 1-4.986-3.119 7.418 7.418 0 0 1-1.278-5.669l3.498-19.373h-7.786l2.464-13.336h7.73L138.775.969l13.401 2.325-2.408 13.333h26.196v13.336h-28.605l-2.699 14.945h6.453l-.086-8.672 13.608-.13.088 8.802h11.241v13.336h-11.11l.122 12.185h10.988zm-53.959-37.176h6.643l-8.593 50.423.065.09h-11.732l6.961-39.487c-3.071-.18-5.514-2.684-5.514-5.737v-.425l.068-.452 3.641-22.877h-2.809V3.751h8.29c.34 0 .686.028 1.022.079a6.537 6.537 0 0 1 4.283 2.563 6.298 6.298 0 0 1 1.186 4.79l-3.511 22.07zM94.076 3.061h13.609V96.87H94.076V3.061zm-21.352 66.68H54.186v13.794h26.75V96.87H49.259c-4.786 0-8.681-3.815-8.681-8.504V11.569c0-4.689 3.895-8.508 8.681-8.508h31.677v13.336h-26.75v40.007h13.609V43.069h-8.838V29.733h13.767c4.786 0 8.682 3.816 8.682 8.508v22.994c0 4.689-3.896 8.506-8.682 8.506zM20.918 88.386l8.815-10.162 9.386 7.82-8.815 10.16-9.386-7.818zm8.397-16.179a7.49 7.49 0 0 1-2.031 5.123L10.636 95.163l-10.041-9 15.11-16.188V50.427H1.863V37.09h13.842V23.984h13.61V37.09h6.335v13.337h-6.335v21.78zM13.081 30.933L.029 27.156l4.649-15.435c.966-3.203 4.02-5.441 7.427-5.441H35.65v13.337H16.49l-3.409 11.316z"></path></svg>
      </router-link>
      <ul class="nav">
        <li class="menu-item">
          <router-link to="/index">首页</router-link>
        </li>
        <li class="menu-item">
          <router-link to="/cs01">橙仕01</router-link>
        </li>
        <li class="menu-item">
          <router-link to="/csx2">橙仕X2</router-link>
        </li>
        <li class="menu-item">
          <router-link to="/csx7">橙仕X7</router-link>
        </li>
        <li class="menu-item">
          <router-link to="/chassis">橙仕二类底盘</router-link>
        </li>
        <!-- <li class="menu-item">
          <router-link to="/antiv">橙仕安特威</router-link>
        </li>
        <li class="menu-item">
          <router-link to="/ec">橙仕01社区版</router-link>
        </li> -->
        <li class="menu-item">
          <router-link to="/0zai">0ZAI零载无人车</router-link>
          <!-- <a href="http://0zai.com" target="_blank">0ZAI零载无人车</a> -->
        </li>
        <!-- <li class="menu-item">
          <router-link to="/ai">橙仕大脑</router-link>
        </li>
        <li class="menu-item">
          <router-link to="/industrial">矩阵工业</router-link>
        </li>
        <li class="menu-item">
          <router-link to="/technical">实验室</router-link>
        </li>
        <li class="menu-item">
          <router-link to="/op">开放平台</router-link>
        </li> -->
        <li class="menu-item">
          <router-link to="/about">关于我们</router-link>
        </li>
      </ul>
      <span class="navTrigger" @click="navToggle"><i></i></span>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  data() {
    const { style, mp } = this.$route.meta;
    const { pathname } = window.location;

    return {
      style,
      mp: mp || pathname === '/StvehicleUr',
      h5Nav: false,
    }
  },
  watch: {
    $route (to, next) {
      const { style, mp } = to.meta;

      if (to.path !== next.path && this.h5Nav) {
        this.navToggle();
      }
      
      this.style = style;
      this.mp = mp;
    }
  },
  methods: {
    navToggle() {
      const { h5Nav } = this;
      const body = document.querySelector('body');
      const { className } = body;
      const cls = 'navShow';
      const classNameArr = className.split(' ');

      if (h5Nav) {
        body.className = classNameArr.filter((_cls) => _cls !== cls).join(' ');

        this.h5Nav = false;
      } else {
        if (classNameArr.some((_cls) => _cls === cls)) return;

        classNameArr.push(cls);

        body.className = classNameArr.join(' ');

        this.h5Nav = true;
      }
    }
  }
}
</script>

<style lang="scss">
#header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: #fff;
  z-index: 99;

  .layout {
    display: flex;
    margin: 0 auto;
    min-height: auto;
    max-width: none;
    padding: 20px 20px;
    height: 110px;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    color: #fff;
    transition: color 0.3s;

    svg {
      display: block;
      width: 180px;
      height: 46px;
    }
  }

  .navTrigger {
    display: none;
  }

  .nav {
    display: flex;
    line-height: 30px;

    li {
      font-size: 18px;
      color: currentColor;

      & + li {
        &::before {
          font-size: 14px;
          content: '|';
        }
      }

      a {
        padding: 0 20px;
        color: currentColor;
        transition: all 0.3s;
        cursor: pointer;
      }

      &:hover > a,
      & > a.router-link-active {
        opacity: 0.65;
      }
    }
  }

  &.dark {
    color: #222226;

    .logo,
    .nav li a {
      color: #222226;
    }
  }

  &.static {
    position: relative;

    &.dark {
      background-color: #fff;
    }

    &.brand {
      background-color: #e83e0b;
    }
  }
}
@media only screen and (min-width: 500px) {
  #header {
    &.brand {
      .layout {
        height: 70px;
      }

      .logo {
        svg {
          width: auto;
          height: 30px;
        }
      }
    }

  }
}
@media only screen and (max-width: 500px) {
  #header {
    .layout {
      padding: 20px;
      height: 80px;
    }

    .logo {
      position: relative;
      z-index: 5;

      svg {
        width: 54px;
        height: auto;
      }
    }
    .nav {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      padding: 80px 20px 20px;
      width: 100%;
      height: 100vh;
      opacity: 0;
      background-color: #05121f;
      transform: translateY(-100%);
      transition: all 0.3s;
      z-index: 1;

      li {
        text-align: center;
        line-height: 40px;

        & + li {
          margin-top: 16px;

          &::before {
            content: unset;
          }

          a {
            display: block;
          }
        }
      }
    }
    .navTrigger {
      display: block;
      margin-right: -6px;
      padding: 15px 6px;

      i {
        display: block;
        position: relative;
        width: 24px;
        height: 3px;
        z-index: 5;
        background-color: #fff;
        transition: all 0.3s;

        &::before,
        &::after {
          position: absolute;
          top: 0;
          left: 0;
          width: 24px;
          height: 3px;
          content: "";
          background-color: #fff;
          transform: translateY(-9px);
          transition: all 0.3s;
        }
        &::after {
          transform: translateY(9px);
        }
      }
    }

    &.dark, &.mDark {
      .navTrigger i {
        &,
        &::before,
        &::after {
          background-color: #222226;
        }
      }
    }
    &.mStatic {
      position: relative;

      &.dark, &.mDark {
        background-color: #fff;
      }

      &.mDark {
        color: #222226;

        .logo,
        .nav li a {
          color: #222226;
        }
      }
    }

    &.mFixed {
      position: absolute;

      &.dark {
        background: none;
      }
    }
  }

  
  body.navShow {
    overflow: hidden;

    #header {
      position: fixed;
      z-index: 999;

      .navTrigger {
        i {
          background-color: transparent;

          &::before {
            background-color: #fff;
            transform: rotate(45deg);
          }
          &::after {
            background-color: #fff;
            transform: rotate(-45deg);
          }
        }
      }

      .nav {
        opacity: 1;
        transform: translateY(0);

        &::before {
          display: block;
          margin-bottom: 62px;
          height: 1px;
          content: "";
          background-color: #fff;
          opacity: 0.27;
        }
      }

      &.dark, &.mDark {
        .navTrigger {
          i {
            &::before, &::after {
              background-color: #fff;
            }
          }
        }
      }

      .logo,
      .nav li a,
      &.dark .logo,
      &.dark .nav li a,
      &.mDark .logo,
      &.mDark .nav li a {
        color: #ffffff;
      }
    }
  }
}
</style>
